<template>
  <main>
    <PageTitle>Kontakt</PageTitle>

    <iframe
      :src="contactFormURL"
      @mouseover="deactivateCursor"
      @mouseout="activateCursor"
    ></iframe>

    <HomePageCTA :data="contentData" />
  </main>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import HomePageCTA from "@/components/HomePageCTA.vue";
import { incrementComponentsLoading, updateCursor } from "@/events";

export default {
  components: { PageTitle, HomePageCTA },

  data: () => {
    return {
      contactFormURL: process.env.VUE_APP_CONTACT_FORM_URL,
      contentData: {
        field_call_to_action_titel: [
          {
            value: "Kontaktieren Sie uns"
          }
        ],
        field_call_to_action: [{ value: "" }],
        field_call_to_action_absatz: [{ value: "" }],
        field_call_to_action_button_besc: [{ value: "" }],
        field_call_to_action_bild: [{ url: "", alt: "" }]
      }
    };
  },

  methods: {
    deactivateCursor() {
      this.$emit(updateCursor, { cursor: "none" });
    },
    activateCursor() {
      this.$emit(updateCursor, { cursor: "default" });
    }
  },

  beforeRouteLeave(to, from, leave) {
    this.activateCursor();
    this.$emit(incrementComponentsLoading);
    leave();
  }
};
</script>

<style scoped>
iframe {
  width: 100%;
  grid-column: 1 / -1;
  border: none;
  height: 100vh;
}
</style>
